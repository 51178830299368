import { Application, Controller } from 'stimulus'
import '@stimulus/polyfills'

import './component.scss'

const linkSelector = '.tab-navigation__tab-link'
const selectedTabLinkClass = 'tab-navigation__tab-link--selected'
const selectedPanelClass = 'tab-content__panel--selected'

const app = Application.start()
app.register('tab-navigation', class extends Controller {
  switchTab (e) {
    const dataset = this.element.dataset
    if (dataset.linkprevent === 'false') return
    e.preventDefault()
    const url = new URL(e.target.href)
    const clickedTabLink = this.element.querySelector(`${linkSelector}[href="${url.hash}"]`)
    const currentSelectedTabLink = this.selectedTabLink()

    const clickedPanelElement = this.element.querySelector(`${url.hash}`)
    const currentSelectedPanel = this.selectedPanel()

    clickedTabLink.classList.add(selectedTabLinkClass)
    clickedPanelElement.classList.add(selectedPanelClass)

    currentSelectedPanel.classList.remove(selectedPanelClass)
    currentSelectedTabLink.classList.remove(selectedTabLinkClass)
  }

  selectedPanel () {
    return this.element.querySelector(`.${selectedPanelClass}`)
  }

  selectedTabLink () {
    return this.element.querySelector(`.${selectedTabLinkClass}`)
  }
})
